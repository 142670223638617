$(document).ready(function() {
  $("#courseMetadata").select2({
    data: [],
    theme: "bootstrap",
    tags: true,
    maximumSelectionLength: 10,
    tokenSeparators: [','],
    placeholder: "Find tags from list or add a new one",
    multiple: true
  });
  $("#coursePartner").select2({
    theme: "bootstrap",
    tags: false,
    maximumSelectionLength: 10,
    tokenSeparators: [','],
    placeholder: "e. g. Redeemable Bitcoin Card with logo",
    multiple: true,
  });

  $("#coursePartner").on('select2:select', function (e) {
    var data = e.params.data;
    var logo = $(data.element).data("logo");
    $(".partner-list").append('<div class="partner" id="partner'+ data.id +'">' +
      '<img id="company-logo" src="'+ logo +'" /> ' +
      // '<div class="company-name">'+ data.text +'</div>' +
      '</div>'
    );
  });
  $("#coursePartner").on('select2:unselect', function (e) {
    var data = e.params.data;
    $(".partner-list").find("#partner"+ data.id).remove();
  });

  if(document.getElementById("organization_course_course_thumbnail")) {
    document.getElementById("organization_course_course_thumbnail").onchange = function () {
      var reader = new FileReader();
      reader.onload = function (e) {
        // get loaded data and render thumbnail.
        document.getElementById("preview-img").src = e.target.result;
        $('#preview-img').show().attr( "src", e.target.result );
      };
      reader.readAsDataURL(this.files[0]);
    };
  };
  $(".lesson-list").sortable({
    update: function(e, ui) {
      $.ajax({
        url: $(this).data('url'),
        type: "PATCH",
        data: $(this).sortable('serialize'),
      });
    }
  });
});
