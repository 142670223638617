//= require jquery
//= require jquery_ujs
//= require jquery-ui
//= require bootstrap/js/bootstrap.min
//= require moment
//= require bootstrap-datetimepicker.min
//= require ckeditor/init
//= require select2-full

$(".ckeditor").each(function(_, ckeditor) {
  CKEDITOR.replace(ckeditor);
});
$(document).ready(function() {
  // var url = window.location.href;
  // var activeTab = url.substring(url.indexOf("#") + 1);
  // if(url.indexOf("#") > -1) {
  //   $(".tab-pane").removeClass("active in");
  //   $("#" + activeTab + "").addClass("active in");
  //   $('a[href="#'+ activeTab +'"]').tab('show');
  // }
  var formmodified=0;
  $('form *').change(function(){
    formmodified=1;
  });
  $("input[name='commit']").click(function() {
    formmodified = 0;
  });
  window.onbeforeunload = confirmExit;
  function confirmExit() {
    if (formmodified == 1) {
      return "New content not saved. Do you wish to leave the page?";
    }
  }

  $('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });

  $("#questionList").sortable({
    update: function(e, ui) {
      $(this).sortable('serialize');
      $.ajax({
        url: $(this).data('url'),
        type: "PATCH",
        data: $(this).sortable('serialize'),
      });
    }
  });

  if (document.getElementById("lesson_thumbnail")) {
    document.getElementById("lesson_thumbnail").onchange = function () {
      var reader = new FileReader();
      reader.onload = function (e) {
        // get loaded data and render thumbnail.
        document.getElementById("preview-img").src = e.target.result;
        $('#preview-img').show().attr( "src", e.target.result );
      };
      reader.readAsDataURL(this.files[0]);
    };
  };
  $("#unitLessonMetadata").select2({
    data: [],
    theme: "bootstrap",
    tags: true,
    maximumSelectionLength: 10,
    tokenSeparators: [','],
    placeholder: "Find tags from list or add a new one",
    multiple: true
  });

  $('body').on('click','.add_question_choice_forms', function(event){
    event.preventDefault()
    let time = new Date().getTime()
    let linkId = this.dataset.id
    let regexp = linkId ? new RegExp(linkId, 'g') : null
    let newFields = regexp ? this.dataset.fields.replace(regexp, time) : null
    newFields ? this.insertAdjacentHTML('beforebegin', newFields) : null
  });

  $('body').on('click','.delete_answer_link', function(event){
    event.preventDefault();
    $(this).parents(".question-choice").remove()
  });

  $('body').on('click','.delete_question_new', function(event){
    event.preventDefault();
    $(this).parents(".question-form").remove()
  });

  $('.add_question_forms').on('click', function(event){
    event.preventDefault()
    let time = new Date().getTime()
    let linkId = this.dataset.id
    let regexp = linkId ? new RegExp(linkId, 'g') : null
    let newFields = regexp ? this.dataset.fields.replace(regexp, time) : null
    newFields ? this.insertAdjacentHTML('beforebegin', newFields) : null
    var textarea = $('.question-form').find('.question-content').last()[0];
    // const CKEDITOR = require('ckeditor4');
    CKEDITOR.replace(textarea);
    $('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });
    checkLessonType();
  });

  function checkLessonType() {
    var lessonType = $("input[name='lesson[lesson_type]']:checked").val();
    var questionDropdowns = $('select.type-of-question');
    if(lessonType === "basic") {
      $('select.type-of-question').find('option[value="free_response"]').addClass("hidden");
      $('select.type-of-question').find('option[value="multiple_choice"]').removeClass("hidden");
      $('select.type-of-question').find('option[value="true_false"]').removeClass("hidden");
      $.each($('select.type-of-question'), function(index, element) {
        var optionValue = $(element).val() == "true_false" ? "true_false" : "multiple_choice";
        var defaultOption = $(element).find('option[value="'+ optionValue +'"]');
        defaultOption.attr("selected", true);
        changeQuestionType(defaultOption);
      })
    } else {
      $('select.type-of-question').find('option[value="multiple_choice"]').addClass("hidden");
      $('select.type-of-question').find('option[value="true_false"]').addClass("hidden");
      $('select.type-of-question').find('option[value="free_response"]').removeClass("hidden");
      $('select.type-of-question').find('option[value="free_response"]').click();
      $.each($('select.type-of-question'), function(index, element) {
        var defaultOption = $(element).find('option[value="free_response"]');
        defaultOption.attr("selected", true);
        changeQuestionType(defaultOption);
      })
    }
  }

  $("body").on('change', '.question-choice input[type=radio]', function() {
    $(this).parents(".choiceAnswer").find(".question-choice").removeClass("correct");
    $(this).parents(".question-choice").addClass("correct")
  });

  function changeQuestionType(el) {
    if(el.val() == "free_response") {
      el.parents(".question-form").find('.choiceFreeResponse').removeClass("hidden");
      el.parents(".question-form").find('.choiceAnswerBoolean').addClass("hidden");
      el.parents(".question-form").find('.choiceAnswer').addClass("hidden");
    } else if(el.val() == "true_false") {
      el.parents(".question-form").find('.choiceFreeResponse').addClass("hidden");
      el.parents(".question-form").find('.choiceAnswer').removeClass("hidden");
      $.each(el.parents(".question-form").find(".question-choice"), function(index, element) {
        if (index == 0) {
          $(element).find('.answer-content').val("True");
          $(element).find('.answer-content').prop("readonly", true);
          $(element).find('.delete_answer_link').addClass('hidden');
        } else if (index == 1) {
          $(element).find('.answer-content').val("False");
          $(element).find('.answer-content').prop("readonly", true);
          $(element).find('.delete_answer_link').addClass('hidden');
        } else {
          $(element).remove();
        };
      });
      if(el.parents(".question-form").find('.choiceAnswer .question-choice').length >= 2) {
        $('.add-more').addClass("hidden");
      }
    } else {
      el.parents(".question-form").find('.choiceFreeResponse').addClass("hidden");
      el.parents(".question-form").find('.choiceAnswerBoolean').addClass("hidden");
      el.parents(".question-form").find('.choiceAnswer').removeClass("hidden");
      el.parents(".question-form").find('.delete_answer_link').removeClass('hidden');
      el.parents(".question-form").find('.answer-content').prop("readonly", false);
      $('.add-more').removeClass("hidden");
    }
  }
  $('body').on('change','.type-of-question', function(){
    changeQuestionType($(this));
  });
  $('body').on('change','input[name="lesson[lesson_type]"]', function(){
    checkLessonType();
  });

  $("body").on("click", "#editQuestionForm .submit-btn", function(e){
    e.preventDefault();
    var msg = "";
    var selectedOption = $("#editQuestionForm select[name='question[question_type]']");
    if(selectedOption.val() == "free_response") {
      var checkboxes = document.querySelectorAll('#editQuestionForm input[type="checkbox"]');
      var checkedOne = Array.prototype.slice.call(checkboxes).some(x => x.checked);
      if(checkedOne == true) {
        $("#editQuestionForm").submit();
      } else {
        msg = "Please select at least one type of responses"
      }
    } else if(selectedOption.val() == "true_false") {
      $("#editQuestionForm").submit();
    } else {
      var radioBoxes = document.querySelectorAll('#editQuestionForm input[type="radio"]');
      var checkedOne = Array.prototype.slice.call(radioBoxes).some(x => x.checked);
      var inputBoxes = document.querySelectorAll('#editQuestionForm input[name="question[question_choice_forms_attributes][][content]"]');

      var hasEmpty = Array.prototype.slice.call(inputBoxes).some(x => x.value == "")
      if(checkedOne == true && hasEmpty == false) {
        $("#editQuestionForm").submit();
      } else {
        msg = "Please fill all the answer and select at least one correct answer"
      }
    }
    $("#editQuestionForm .errorMsg").html(msg);
  });

  $.fn.extend({
   trackChanges: function() {
    $(document).on('change', $(this).find(':input'), function (e) {
      var el = $(e.target);
      $(el).closest('form').data("changed", true);
     });
   }
   ,
   isChanged: function() {
     return this.data("changed");
   }
  });

  $('#editQuestion').on("hide.bs.modal", function() {
    var formmodified = 0;
    $("#editQuestionForm").trackChanges();

    if ($("#editQuestionForm").isChanged()) {
      formmodified = 1;
    }

    window.onbeforeunload = confirmExit;
    function confirmExit() {
      if (formmodified == 1) {
        return "New content not saved. Do you wish to leave the page?";
      }
    }
  })

});
